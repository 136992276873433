<template>
  <Component
    :is="buttonOrNuxtLink"
    v-bind="to ? { to: to } : {}"
    :target="$isExternalUrl(to) ? '_blank' : ''"
    class="focus-default text-w font-sans-heavy-900 inline-block rounded-[1.25rem] bg-main-green px-8 py-2 text-sm leading-[1.375rem] ring-main-green transition-colors sm:rounded-[1.6875rem] sm:px-9 sm:py-3 sm:text-base sm:leading-[1.5625rem]"
    :class="
      isWhite
        ? 'bg-white text-main-blue-dark-hover visited:text-main-blue-dark-hover hover:bg-second-blue-200 ring-white hover:ring-second-blue-200 ring-offset-main-blue-dark-hover'
        : 'text-white hover:bg-main-green-hover hover:ring-main-green-hover visited:text-white'
    "
  >
    <slot />
  </Component>
</template>

<script setup>
const props = defineProps({
  to: { type: [String, Object], default: null },
  isWhite: { type: Boolean, default: false },
})

// determine whether to load NuxtLink or button
const buttonOrNuxtLink = computed(() => {
  if (props.to) {
    return resolveComponent('NuxtLink')
  }

  return 'button'
})
</script>
